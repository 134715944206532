import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "AlmacenamientoPage",
    data() {
        let ss = new MainService();
        return {
            msg: "AlmacenamientoPage",
            ss: ss,
            auth: {},
            ajax: {
                "url": ss.indexAlmacenamiento(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'id', name: 'a.id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'Producto', name: 'pr.Producto', title: 'Producto' },
                { data: 'Fecha', name: 'p.Fecha', title: 'Fecha' },
                { data: 'CantidadTotal', name: 'a.CantidadTotal', title: 'Cantidad Total' },
                { data: 'Maples', name: 'a.Maples', title: 'Maples' },
                { data: 'Cantidad', name: 'a.Cantidad', title: 'UU. sobrantes' },
                { data: 'Nombre', name: 'tt.Nombre', title: 'Tipo Tamaño' },
                { data: 'Unidad', name: 'um.Unidad', title: 'Unidad Medida' },
                { data: 'FechaEntrada', name: 'a.FechaEntrada', title: 'Fecha Entrada' },
                { data: 'FechaCaducidad', name: 'a.FechaCaducidad', title: 'Fecha Caducidad' },
                { data: 'Calidad', name: 'a.Calidad', title: 'Calidad' },
                { data: 'Estado', name: 'ep.Estado', title: 'Estado' },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],


            isLoading: false,
            isLoadingFile: false,
            errorBag: {},
            almacenamientos: [],
            almacenamiento: {
                Produccion: '',
            },
            producciones: [],
            tipotamanos: [],
            unidadmedidas: [],
            estadoproducciones: [],
            sumasPorTamano: [],
            totalCantidades: 0,
        };
    },
    watch: {
        'almacenamiento.Produccion'(nuevoValor) {
        if (nuevoValor) {
            this.obtenerSumasPorTamano(nuevoValor);
        }
    },
        'almacenamiento.FechaEntrada': function (newFechaEntrada) {
            if (newFechaEntrada) {
                let fechaEntrada = new Date(newFechaEntrada);
                let fechaCaducidad = new Date(fechaEntrada);
                fechaCaducidad.setDate(fechaEntrada.getDate() + 10); // Sumar 10 días

                // Formatear la fecha para que sea compatible con el campo de entrada de tipo fecha (YYYY-MM-DD)
                let year = fechaCaducidad.getFullYear();
                let month = (fechaCaducidad.getMonth() + 1).toString().padStart(2, '0');
                let day = fechaCaducidad.getDate().toString().padStart(2, '0');
                this.almacenamiento.FechaCaducidad = `${year}-${month}-${day}`;
            }
        },
        'tipotamanos': {
            handler: function () {
                this.calcularTotalCantidades();
            },
            deep: true
        }
    },
    methods: {
        newAlmacenamiento() {
            this.almacenamiento = {
                Produccion: null,
                UbicacionAlmacenamiento: 'Almacen BPE-VI', // puedes cambiar según el valor por defecto que quieras
                Cantidad: null,
                TipoTamano: null,
                FechaEntrada: '',
                FechaCaducidad: '',
                UnidadMedida: null,
                Calidad: 'Primera Calidad',
            };
            this.tipotamanos = this.tipotamanos.map(tamano => {
                return {
                    ...tamano,  // Copia todas las propiedades del objeto tamano
                    cantidad: null  // Reinicializa el campo cantidad
                };
            });
        
            this.errorBag = {}; // Reinicializa los errores previos
            this.$refs['frm-almacenamiento'].show();
        },
        showAlmacenamiento(id) {
            this.isLoading = true;
            this.ss.showAlmacenamiento(id).then(
                (result) => {
                    let response = result.data;
                    this.almacenamiento = response.data;
                    this.$refs['view-almacenamiento'].show();
                    this.isLoading = false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading = false;
            });
        },
        editAlmacenamiento() {
            this.$refs['frm-almacenamiento'].show();
            this.$refs['view-almacenamiento'].hide();
        },
        cancelAlmacenamiento() {
            if (this.almacenamiento.id) {
                this.$refs['view-almacenamiento'].show();
            }
            this.$refs['frm-almacenamiento'].hide();
        },
        saveAlmacenamiento() {
            this.almacenamiento.Calidad = 'Primera Calidad';
            this.almacenamiento.UbicacionAlmacenamiento = 'Almacen BPE-VI';
            this.almacenamiento.UnidadMedida = 1;
        
            // Crear un arreglo para almacenar los tamaños y sus cantidades
            const cantidadesPorTipo = this.tipotamanos.map(tamano => {
                return {
                    TipoTamano: tamano.id,
                    Cantidad: tamano.cantidad || 0  // Si no tiene cantidad, asigna 0
                };
            });
        
            // Añadir el arreglo a almacenamiento
            this.almacenamiento.CantidadesPorTipo = cantidadesPorTipo;
        
            console.log('esto va a alma', this.almacenamiento);
        
            this.ss.storeAlmacenamiento(this.almacenamiento).then(
                (result) => {
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    );
                    this.resetAlmacenamiento();
                    this.resetSumas();
                    this.getProduccion();
                    this.$refs['frm-almacenamiento'].hide();
                    this.$refs['datatable-almacenamiento'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    console.log(error.response.data);
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    );
                });
        },
        

        deleteAlmacenamiento() {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
                showCancelButton: true,
                cancelButtonColor: '#d33',
            })
                .then((willDelete) => {
                    if (willDelete.isConfirmed) {
                        this.ss.destroyAlmacenamiento(this.almacenamiento)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-almacenamiento'].hide();
                                this.$refs['datatable-almacenamiento'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        draw() {
            window.$('.btn-datatable-Almacenamiento').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showAlmacenamiento(data);
            });
        },
        getProduccion() {
            this.ss.listProduccionAlmacen().then(
                (result) => {
                    let response = result.data;
                    this.producciones = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
       
        getUnidadMedida() {
            this.ss.listUnidadMedida().then(
                (result) => {
                    let response = result.data;
                    this.unidadmedidas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getEstadoProduccion() {
            this.ss.listEstadoProduccion().then(
                (result) => {
                    let response = result.data;
                    this.estadoproducciones = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        obtenerSumasPorTamano(produccionId) {
            console.log('este id se va a suma', produccionId);
            this.ss.getSumaPorTamano(produccionId)
                .then(response => {
                    this.sumasPorTamano = response.data;
                })
                .catch(error => {
                    console.error("Error al obtener sumas por tamaño", error);
                });
        },
        resetAlmacenamiento() {
            this.almacenamiento = {
                Produccion: null,
                UbicacionAlmacenamiento: '',
                Cantidad: null,
                TipoTamano: null,
                FechaEntrada: '',
                FechaCaducidad: '',
                UnidadMedida: null,
                Calidad: '',
            };
            this.errorBag = {};
        },

        resetSumas() {
            this.sumaTotal = 0;
            this.sumasPorTamano = [];
        },
        obtenerTiposTamano() {
            this.ss.listTipoTamanoAlma().then(result => {
                let response = result.data;
                this.tipotamanos = response.data; // Asegúrate de traer los datos correctos
            }).catch(error => {
                console.log(error);
            });
        },
        calcularTotalCantidades() {
            this.totalCantidades = this.tipotamanos.reduce((sum, tamano) => {
                return sum + (parseInt(tamano.cantidad) || 0); // Suma de las cantidades ingresadas
            }, 0);
        },
    },
    computed: {
        sumaTotal() {
            return this.sumasPorTamano.reduce((total, item) => {
                // Convertimos item.totalCantidad a número y sumamos solo si es un número válido
                let cantidad = parseFloat(item.totalcantidad);
                return total + (isNaN(cantidad) ? 0 : cantidad);
            }, 0);
        }
    }
    ,
    components: {
        dataTable,
        Loader
    },
    mounted() {

        var persona = JSON.parse(localStorage.getItem('persona'));
        this.auth = JSON.parse(localStorage.getItem("persona"));
        if (!persona) {
            this.$router.push('/Login');
        } else {
            this.getProduccion();
            this.getUnidadMedida();
            this.getEstadoProduccion();
            this.obtenerTiposTamano();
        }
    }
};
