<template>
	<div>
		<!-- begin page-header -->
		<h1 class="page-header">TABLERO DE CONTROL <small>Datos Estadísticos del Sistema</small></h1>
		<!-- end page-header -->
		<!-- begin row -->
		<div class="row">
			<!-- begin col-3 -->
			<div class="col-xl-3 col-md-6">
				<div class="widget widget-stats bg-teal">
					<div class="stats-icon stats-icon-lg"><i class="fa fa-egg fa-fw"></i></div>
					<div class="stats-content">
						<div class="stats-title">HUEVOS PRODUCIDOS</div>
						<div class="stats-number">9200</div>
						<div class="stats-progress progress">
							<div class="progress-bar" style="width: 85%;"></div>
						</div>
						<div class="stats-desc">Cantidad total de huevos categorizados este mes</div>
					</div>
				</div>
			</div>
			<!-- end col-3 -->
			<!-- begin col-3 -->
			<div class="col-xl-3 col-md-6">
				<div class="widget widget-stats bg-blue">
					<div class="stats-icon stats-icon-lg"><i class="fa fa-carrot fa-fw"></i></div>
					<div class="stats-content">
						<div class="stats-title">CEBOLLAS PRODUCIDAS</div>
						<div class="stats-number">3500</div>
						<div class="stats-progress progress">
							<div class="progress-bar" style="width: 65%;"></div>
						</div>
						<div class="stats-desc">Producción de cebollas este mes</div>
					</div>
				</div>
			</div>
			<!-- end col-3 -->
			<!-- begin col-3 -->
			<div class="col-xl-3 col-md-6">
				<div class="widget widget-stats bg-indigo">
					<div class="stats-icon stats-icon-lg"><i class="fa fa-tag fa-fw"></i></div>
					<div class="stats-content">
						<div class="stats-title">PRECIOS PROMEDIO</div>
						<div class="stats-number">3.50 Bs</div>
						<div class="stats-progress progress">
							<div class="progress-bar" style="width: 70%;"></div>
						</div>
						<div class="stats-desc">Precio promedio por tamaño de huevos y cebollas</div>
					</div>
				</div>
			</div>
			<!-- end col-3 -->
			<!-- begin col-3 -->
			<div class="col-xl-3 col-md-6">
				<div class="widget widget-stats bg-dark">
					<div class="stats-icon stats-icon-lg"><i class="fa fa-cubes fa-fw"></i></div>
					<div class="stats-content">
						<div class="stats-title">TIPOS DE TAMAÑO</div>
						<div class="stats-number">5</div>
						<div class="stats-progress progress">
							<div class="progress-bar" style="width: 90%;"></div>
						</div>
						<div class="stats-desc">Cantidad de tamaños categorizados en huevos y cebollas</div>
					</div>
				</div>
			</div>
			<!-- end col-3 -->
		</div>
		<!-- end row -->
		<!-- begin row -->
		<div class="row">
			<!-- begin col-8 -->
			<div class="col-xl-8">
				<div class="widget-chart with-sidebar inverse-mode">
					<div class="widget-chart-content bg-dark">
						<h4 class="chart-title">
							PRODUCCIÓN MENSUAL
							<small>Comparación entre huevos y cebollas</small>
						</h4>
						<div class="widget-chart-full-width nvd3-inverse-mode" style="height: 260px">
							<vn-stacked-area :model="areaChart.data" yFormat=",.0f" :xFormat="formatDate"
								:clipEdge="areaChart.clipEdge" :rightAlignYAxis="areaChart.rightAlignYAxis"
								:showControls="areaChart.showControls"></vn-stacked-area>
						</div>
					</div>
					<div class="widget-chart-sidebar bg-dark-darker">
						<div class="chart-number">
							12,000
							<small>Producción Total</small>
						</div>
						<div class="flex-grow-1 d-flex align-items-center">
							<div class="nvd3-inverse-mode" style="height: 180px">
								<vn-pie :model="donutChart.data" :donut="donutChart.donut" text-field="label"
									value-field="value" :show-tooltip-percent="true" :show-legend="false"></vn-pie>
							</div>
						</div>
						<ul class="chart-legend f-s-11">
							<li><i class="fa fa-circle fa-fw text-primary f-s-9 m-r-5 t-minus-1"></i> 76.7%
								<span>Huevos</span></li>
							<li><i class="fa fa-circle fa-fw text-success f-s-9 m-r-5 t-minus-1"></i> 23.3%
								<span>Cebollas</span></li>
						</ul>
					</div>
				</div>
			</div>
			<!-- end col-8 -->
			<!-- begin col-4 -->
			<div class="col-xl-4">
				<panel title="Resumen de Precios" noBody="true" noButton="true">
					<template slot="outsideBody">
						<div class="list-group">
							<a href="javascript:;"
								class="list-group-item list-group-item-action list-group-item-inverse d-flex justify-content-between align-items-center text-ellipsis">
								Huevos - AAA
								<span class="badge bg-teal f-s-10">3.00 Bs</span>
							</a>
							<a href="javascript:;"
								class="list-group-item list-group-item-action list-group-item-inverse d-flex justify-content-between align-items-center text-ellipsis">
								Cebollas - Grande
								<span class="badge bg-blue f-s-10">2.50 Bs</span>
							</a>
							<a href="javascript:;"
								class="list-group-item list-group-item-action list-group-item-inverse d-flex justify-content-between align-items-center text-ellipsis">
								Huevos - AA
								<span class="badge bg-indigo f-s-10">2.80 Bs</span>
							</a>
						</div>
					</template>
				</panel>
			</div>
			<!-- end col-4 -->
		</div>
		<div class="row">
			<!-- begin col-8 -->
			<div class="col-xl-8">
				<panel title="Ventas Diarias (Últimos 10 días)" noButton="true">
					<template slot="body">
						<line-chart :data="salesData"></line-chart>
					</template>
				</panel>
			</div>
			<!-- end col-8 -->
			<div class="col-xl-4">
				<panel title="Porcentaje de Postura (Últimos 10 días)" noButton="true">
					<template slot="body">
						<bar-chart :data="posturaData"></bar-chart>
					</template>
				</panel>
			</div>
		</div>
		<!-- end row -->
	</div>
</template>

<script>
import BarChart from '@/components/vue-chartjs/BarChart.js';
import LineChart from '@/components/vue-chartjs/LineChart.js';
export default {
	name: 'DashboardV2',
	data() {
		return {
			areaChart: {
				rightAlignYAxis: false,
				showControls: false,
				clipEdge: false,
				data: [{
					'key': 'Huevos',
					'color': '#5AC8FA',
					'values': [
						{ x: 1, y: 1200 }, { x: 2, y: 1100 }, { x: 3, y: 1300 },
						{ x: 4, y: 1000 }, { x: 5, y: 1400 }, { x: 6, y: 1200 },
						{ x: 7, y: 1600 }, { x: 8, y: 1700 }, { x: 9, y: 1800 },
						{ x: 10, y: 2000 }
					]
				}, {
					'key': 'Cebollas',
					'color': '#348fe2',
					'values': [
						{ x: 1, y: 600 }, { x: 2, y: 500 }, { x: 3, y: 700 },
						{ x: 4, y: 800 }, { x: 5, y: 900 }, { x: 6, y: 700 },
						{ x: 7, y: 600 }, { x: 8, y: 500 }, { x: 9, y: 400 },
						{ x: 10, y: 300 }
					]
				}]
			},
			donutChart: {
				donut: true,
				data: [{
					'label': 'Huevos',
					'value': 9200,
					'color': '#5AC8FA'
				}, {
					'label': 'Cebollas',
					'value': 3500,
					'color': '#348fe2'
				}]
			},
			salesData: {
				labels: ['Día 1', 'Día 2', 'Día 3', 'Día 4', 'Día 5', 'Día 6', 'Día 7', 'Día 8', 'Día 9', 'Día 10'],
				datasets: [
					{
						label: 'Ventas Diarias (Bs)',
						backgroundColor: '#5AC8FA',
						data: [900, 850, 1000, 950, 800, 1200, 1100, 1000, 1050, 900]
					}
				]
			},
			posturaData: {
				labels: ['Día 1', 'Día 2', 'Día 3', 'Día 4', 'Día 5', 'Día 6', 'Día 7', 'Día 8', 'Día 9', 'Día 10'],
				datasets: [
					{
						label: 'Porcentaje de Postura (%)',
						backgroundColor: '#348fe2',
						data: [85, 80, 90, 88, 84, 92, 89, 85, 86, 87]
					}
				]
			}
		}
	},
	components: {
        BarChart,
        LineChart
    },
	methods: {
		formatDate(d) {
			var monthsName = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
			d = new Date(d);
			return monthsName[d.getMonth()] + ' ' + d.getDate();
		},
	}
}
</script>
