import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "TipoTamanoPage",
    data() {
        let ss = new MainService();
        return {
            msg: "TipoTamanoPage",
            ss: ss,
            auth: {},

            ajax: {
                "url": ss.indexTipoTamano(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'Nombre', name: 'Nombre', title: 'Tamaño' },
                { data: 'Descripcion', name: 'Descripcion', title: 'Descripción' },
                { data: 'Producto', name: 'Producto', title: 'Producto' },
                { data: 'CantidadTotal', name: 'CantidadTotal', title: 'Cantidad Total' },
                { data: 'CantidadDisponible', name: 'CantidadDisponible', title: 'Cantidad Disponible' },
                { data: 'CantidadVendida', name: 'CantidadVendida', title: 'Cantidad Vendida' },
                { data: 'CantidadReservada', name: 'CantidadReservada', title: 'Cantidad Reservada' },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            isLoading: false,
            isLoadingFile: false,
            errorBag: {},
            tipotamanos: [],
            tipotamano: {},
            
        };
    },
    methods: {       
        newTipoTamano() {
            this.tipotamano = {};
            this.$refs['frm-tipotamano'].show();
        },
        ActualizarTipoTamano() {
            this.isLoading = true; 
            this.ss.actualizartipotamano()
                .then((response) => {
                    this.isLoading = false; 
                    this.$bvToast.toast('Tabla actualizada correctamente', {
                        title: 'Éxito',
                        variant: 'success',
                        autoHideDelay: 5000
                    });
                    console.log(response.data);
                })
                .catch((error) => {
                    this.isLoading = false; 
                    console.error(error);
                    this.$bvToast.toast('Error al actualizar la tabla', {
                        title: 'Error',
                        variant: 'danger',
                        autoHideDelay: 5000
                    });
                });
        },
        showTipoTamano(id) {
            this.isLoading=true;
            this.ss.showTipoTamano(id).then(
                (result) => {
                    let response = result.data;
                    this.tipotamano = response.data;
                    this.$refs['view-tipotamano'].show();
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },
        editTipoTamano() {
            this.$refs['frm-tipotamano'].show();
            this.$refs['view-tipotamano'].hide();
        },
        cancelTipoTamano() {
            if (this.tipotamano.id) {
                this.$refs['view-tipotamano'].show();
            }
            this.$refs['frm-tipotamano'].hide();
        },
        saveTipoTamano() {
            this.ss.storeTipoTamano(this.tipotamano).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-tipotamano'].hide();
                    this.$refs['datatable-tipotamano'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    console.log(error.response.data);
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        deleteTipoTamano() {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
                showCancelButton: true,
                cancelButtonColor: '#d33',
            })
                .then((willDelete) => {
                    if (willDelete.isConfirmed) {
                        this.ss.destroyTipoTamano(this.tipotamano)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-tipotamano'].hide();
                                this.$refs['datatable-tipotamano'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        draw() {
            window.$('.btn-datatable-TipoTamano').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showTipoTamano(data);
            });
        }
    },
    components: {
        dataTable,
        Loader
    },
    mounted() {
        
        var persona = JSON.parse(localStorage.getItem('persona'));
        this.auth = JSON.parse(localStorage.getItem("persona"));
        if (!persona) {
            this.$router.push('/Login');
        } 
    }
};
