import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "ProduccionPage",
    data() {
        let ss = new MainService();
        return {
            msg: "ProduccionPage",
            ss: ss,
            auth: {},

            ajax: {
                "url": ss.indexProduccion(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'Producto', name: 'Producto', title: 'Producto' },
                { data: 'Fecha', name: 'Fecha', title: 'Fecha' },
                { data: 'CantidadProducida', name: 'CantidadProducida', title: 'Cantidad Producida' },
                { data: 'Ubicacion', name: 'Ubicacion', title: 'Ubicación' },
                { 
                    data: 'PorcentajePostura', 
                    name: 'PorcentajePostura', 
                    title: 'Porcentaje Postura',
                    render: function(data) {
                        return '<span style="color: green; font-weight: bold;">' + data + '%</span>';
                    }
                },
                { data: 'Parametro', name: 'Parametro', title: 'Parámetro' },
                { data: 'Estado', name: 'Estado', title: 'Estado' },
                { data: 'Persona', name: 'Persona', title: 'PersonaResponsable' },
                { data: 'Activo', name: 'Activo', title: 'Entregado', searchable: false, width: '10%',
                    className: 'centradito',
                    render: function (data, type, row) {
                        if (row.Activo == false) {
                            return '<i class="fa fa-ban text-danger"> No Entregado</i>';
                        } else {
                            return '<i class="fa fa-check text-success"> Entregado</i>';
                        }
                    },
                },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            isLoading: false,
            isLoadingFile: false,
            errorBag: {},
            produccions: [],
            produccion: {},
            productos: [],
            estadoproducciones: [],
            personas: [],

        };
    },
    methods: {       
        newProduccion() {
            this.produccion = {};
            this.$refs['frm-produccion'].show();
        },
        showProduccion(id) {
            this.isLoading=true;
            this.ss.showProduccion(id).then(
                (result) => {
                    let response = result.data;
                    this.produccion = response.data;
                    this.$refs['view-produccion'].show();
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },
        editProduccion() {
            this.$refs['frm-produccion'].show();
            this.$refs['view-produccion'].hide();
        },
        cancelProduccion() {
            if (this.produccion.id) {
                this.$refs['view-produccion'].show();
            }
            this.$refs['frm-produccion'].hide();
        },
        saveProduccion() {
            this.produccion.Ubicacion = 'Galpon BPE-VI'
            this.produccion.Producto = 1
            this.ss.storeProduccion(this.produccion).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-produccion'].hide();
                    this.$refs['datatable-produccion'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    console.log(error.response.data);
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        deleteProduccion() {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
                showCancelButton: true,
                cancelButtonColor: '#d33',
            })
                .then((willDelete) => {
                    if (willDelete.isConfirmed) {
                        this.ss.destroyProduccion(this.produccion)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-produccion'].hide();
                                this.$refs['datatable-produccion'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        draw() {
            window.$('.btn-datatable-Produccion').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showProduccion(data);
            });
        },
        getProducto() {
            this.ss.listProducto().then(
                (result) => {
                    let response = result.data;
                    this.productos = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getEstadoProduccion() {
            this.ss.listEstadoProduccion().then(
                (result) => {
                    let response = result.data;
                    this.estadoproducciones = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getPersona() {
            this.ss.listPersona().then(
                (result) => {
                    let response = result.data;
                    this.personas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
    },
    components: {
        dataTable,
        Loader
    },
    mounted() {
        
        var persona = JSON.parse(localStorage.getItem('persona'));
        this.auth = JSON.parse(localStorage.getItem("persona"));
        if (!persona) {
            this.$router.push('/Login');
        } else{
            this.getProducto();
            this.getEstadoProduccion();
            this.getPersona();
        }
    }
};
