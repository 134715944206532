import MainService from "@/services/MainService.js";
import Vue from "vue";
import VueAxios from "vue-axios";
import axios from "axios";
import msal from "vue-msal";
// import QrcodeVue from "qrcode.vue";


import PageOptions from '../../config/PageOptions.vue'

// const moment = require("moment");
// var crypto = require("crypto");

Vue.use(VueAxios, axios);
// Vue.use( axios);

Vue.use(msal, {
    auth: {
        clientId: process.env.VUE_APP_MSAL_CLIENT_ID,
        tenantId: process.env.VUE_APP_MSAL_TENANT_ID,
        redirectUri: process.env.VUE_APP_MSAL_REDIRECT_URI,
    },
});

export default {
    name: "LoginPage",
    data() {
        let ss = new MainService();
        return {
            ss: ss,
            loginRequest: {},
            errorBag: {},
            hash: "",
            size: 300,
            alias: "",
            channel: "",
            name: process.env.VUE_APP_TITLE,


            bg: {
				activeImg: '/assets/img/login-bg/login-bg-17.jpg',
				bg1: {
					active: true,
					img: '/assets/img/login-bg/login-bg-17.jpg'
				},
				bg2: {
					active: false,
					img: '/assets/img/login-bg/login-bg-16.jpg'
				},
				bg3: {
					active: false,
					img: '/assets/img/login-bg/login-bg-15.jpg'
				},
				bg4: {
					active: false,
					img: '/assets/img/login-bg/login-bg-14.jpg'
				},
				bg5: {
					active: false,
					img: '/assets/img/login-bg/login-bg-13.jpg'
				},
				bg6: {
					active: false,
					img: '/assets/img/login-bg/login-bg-12.jpg'
				}
			}
        };
    },
    components: {
        // QrcodeVue,
    },
    props: {
        msg: String,
    },

    created() {
		PageOptions.pageEmpty = true;
	},
	beforeRouteLeave (to, from, next) {
		PageOptions.pageEmpty = false;
		next();
	},
    
    methods: {
        login() {
            this.ss
                .login(this.loginRequest)
                .then((result) => {
                    console.log(result);
                    localStorage.setItem("persona", JSON.stringify(result.data.data));
                    localStorage.setItem("token", JSON.stringify(result.data.token));
                    this.$router.push("/Profile");
                    this.$router.go();
                    /*var persona = JSON.parse(localStorage.getItem('persona'));
                                  console.log(persona)*/
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast("Problema al Iniciar Sesion", {
                        title: "Error",
                        variant: "danger",
                        autoHideDelay: 5000,
                    });
                });
        },
        authenticate() {
            if (!this.$msal.isAuthenticated()) {
                this.$msal.signIn();
            }
        },
        // showCodigoQR() {
        //     this.$refs["view-codigoqr"].show();
        // },
        // cancelCodigoQR() {
        //     this.$refs["view-codigoqr"].hide();
        // },


        // pagina 

        checkForm: function(e) {
			e.preventDefault();
			this.$router.push({ path: '/dashboard/v2'})
		},
		select: function(x) {
			this.bg.bg1.active = false;
			this.bg.bg2.active = false;
			this.bg.bg3.active = false;
			this.bg.bg4.active = false;
			this.bg.bg5.active = false;
			this.bg.bg6.active = false;
			
			switch (x) {
				case 'bg1': 
					this.bg.bg1.active = true;
					this.bg.activeImg = this.bg.bg1.img;
					break;
				case 'bg2': 
					this.bg.bg2.active = true;
					this.bg.activeImg = this.bg.bg2.img;
					break;
				case 'bg3': 
					this.bg.bg3.active = true;
					this.bg.activeImg = this.bg.bg3.img;
					break;
				case 'bg4': 
					this.bg.bg4.active = true;
					this.bg.activeImg = this.bg.bg4.img;
					break;
				case 'bg5': 
					this.bg.bg5.active = true;
					this.bg.activeImg = this.bg.bg5.img;
					break;
				case 'bg6': 
					this.bg.bg6.active = true;
					this.bg.activeImg = this.bg.bg6.img;
					break;
			}
		}
    },
    mounted() {
        // this.channel = localStorage.getItem("channel");
        // if (this.channel !== "") {
        //     this.$socket.emit("leave", { room: this.channel });
        // }

        // this.valueQR = new moment().format() + "|" + "safi";
        // this.channel = crypto
        //     .createHash("md5")
        //     .update(this.valueQR)
        //     .digest("hex");
        // this.hash = this.channel + "|" + "safi";
        // localStorage.setItem("channel", this.channel);

        // this.$socket.emit("join", { room: this.channel });

        var persona = JSON.parse(localStorage.getItem("persona"));
        if (persona) {
            localStorage.clear();
        }
        if (this.$msal.isAuthenticated()) {
            console.log(this.$msal.data);
            let loginTokenRequest = {
                token: this.$msal.data.user.accountIdentifier,
                email: this.$msal.data.user.userName,
            };
            this.ss
                .loginToken365(loginTokenRequest)
                .then((result) => {
                    console.log(result);
                    localStorage.setItem("persona", JSON.stringify(result.data.data));
                    localStorage.setItem("token", JSON.stringify(result.data.token));
                    this.$router.push("/");
                    this.$router.go();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast("Problema al Iniciar Sesion", {
                        title: "Error",
                        variant: "danger",
                        autoHideDelay: 5000,
                    });
                    this.$msal.signOut();
                });
        } else {
            console.log("no esta logeeado");
        }
    },
};
