var axios = require('axios');

export default class MainService {

    constructor() {
        var token = JSON.parse(localStorage.getItem('token'));
        axios.defaults.baseURL = process.env.VUE_APP_MAIN_SERVICE;
        if (token) {
            axios.defaults.headers.common = {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        } else {
            axios.defaults.headers.common = {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }
    }

    getToken() {
        return axios.defaults.headers.common;
    }

    login(item) {
        return axios.post('api/login', item);
    }

    actualizartipotamano() {
        return axios.get('api/actualizar-tipo-tamano');
    }

    uploadFile(item) {
        return axios.post('api/uploadFile', item);
    }

    loginToken365(item) {
        return axios.post('api/loginToken365', item);
    }

    indexPersona() {
        return axios.defaults.baseURL + 'api/Persona/index';
    }

    showPersona(id) {
        return axios.get('api/Persona/show?id=' + id);
    }

    listPersona() {
        return axios.get('api/Persona/list');
    }

    storePersona(item) {
        return axios.post('api/Persona/store', item);
    }

    destroyPersona(item) {
        return axios.post('api/Persona/destroy', item);
    }

    changePassword(item) {
        return axios.post('api/Persona/changePassword', item);
    }

    //ROL

    showRol(id) {
        return axios.get('api/Rol/show?id=' + id);
    }

    indexRol() {
        return axios.defaults.baseURL + 'api/Rol/index';
    }

    listRol() {
        return axios.get('api/Rol/list');
    }

    storeRol(item) {
        return axios.post('api/Rol/store', item);
    }

    destroyRol(item) {
        return axios.post('api/Rol/destroy', item);
    }

    //FUERZA

    showFuerza(id) {
        return axios.get('api/Fuerza/show?id=' + id);
    }

    indexFuerza() {
        return axios.defaults.baseURL + 'api/Fuerza/index';
    }

    listFuerza() {
        return axios.get('api/Fuerza/list');
    }

    storeFuerza(item) {
        return axios.post('api/Fuerza/store', item);
    }

    destroyFuerza(item) {
        return axios.post('api/Fuerza/destroy', item);
    }

    //DEPARTAMENTO

    showDepartamento(id) {
        return axios.get('api/Departamento/show?id=' + id);
    }

    indexDepartamento() {
        return axios.defaults.baseURL + 'api/Departamento/index';
    }

    listDepartamento() {
        return axios.get('api/Departamento/list');
    }

    storeDepartamento(item) {
        return axios.post('api/Departamento/store', item);
    }

    destroyDepartamento(item) {
        return axios.post('api/Departamento/destroy', item);
    }

    //ESPECIALIDAD

    showEspecialidad(id) {
        return axios.get('api/Especialidad/show?id=' + id);
    }

    indexEspecialidad() {
        return axios.defaults.baseURL + 'api/Especialidad/index';
    }

    listEspecialidad() {
        return axios.get('api/Especialidad/list');
    }

    storeEspecialidad(item) {
        return axios.post('api/Especialidad/store', item);
    }

    destroyEspecialidad(item) {
        return axios.post('api/Especialidad/destroy', item);
    }
    //Cargo

    showCargo(id) {
        return axios.get('api/Cargo/show?id=' + id);
    }

    indexCargo() {
        return axios.defaults.baseURL + 'api/Cargo/index';
    }

    listCargo() {
        return axios.get('api/Cargo/list');
    }

    storeCargo(item) {
        return axios.post('api/Cargo/store', item);
    }

    destroyCargo(item) {
        return axios.post('api/Cargo/destroy', item);
    }
    //Reparticion

    showReparticion(id) {
        return axios.get('api/Reparticion/show?id=' + id);
    }

    indexReparticion() {
        return axios.defaults.baseURL + 'api/Reparticion/index';
    }

    listReparticion() {
        return axios.get('api/Reparticion/list');
    }

    storeReparticion(item) {
        return axios.post('api/Reparticion/store', item);
    }

    destroyReparticion(item) {
        return axios.post('api/Reparticion/destroy', item);
    }
    //Escalafon

    showEscalafon(id) {
        return axios.get('api/Escalafon/show?id=' + id);
    }

    indexEscalafon() {
        return axios.defaults.baseURL + 'api/Escalafon/index';
    }

    listEscalafon() {
        return axios.get('api/Escalafon/list');
    }

    storeEscalafon(item) {
        return axios.post('api/Escalafon/store', item);
    }

    destroyEscalafon(item) {
        return axios.post('api/Escalafon/destroy', item);
    }
    //Grado

    showGrado(id) {
        return axios.get('api/Grado/show?id=' + id);
    }

    indexGrado() {
        return axios.defaults.baseURL + 'api/Grado/index';
    }

    listGrado() {
        return axios.get('api/Grado/list');
    }

    storeGrado(item) {
        return axios.post('api/Grado/store', item);
    }

    destroyGrado(item) {
        return axios.post('api/Grado/destroy', item);
    }

    //Sexo

    showSexo(id) {
        return axios.get('api/Sexo/show?id=' + id);
    }

    indexSexo() {
        return axios.defaults.baseURL + 'api/Sexo/index';
    }

    listSexo() {
        return axios.get('api/Sexo/list');
    }

    storeSexo(item) {
        return axios.post('api/Sexo/store', item);
    }

    destroySexo(item) {
        return axios.post('api/Sexo/destroy', item);
    }

    //GrupoSanguineo

    showGrupoSanguineo(id) {
        return axios.get('api/GrupoSanguineo/show?id=' + id);
    }

    indexGrupoSanguineo() {
        return axios.defaults.baseURL + 'api/GrupoSanguineo/index';
    }

    listGrupoSanguineo() {
        return axios.get('api/GrupoSanguineo/list');
    }

    storeGrupoSanguineo(item) {
        return axios.post('api/GrupoSanguineo/store', item);
    }

    destroyGrupoSanguineo(item) {
        return axios.post('api/GrupoSanguineo/destroy', item);
    }

    //EstadoCivil

    showEstadoCivil(id) {
        return axios.get('api/EstadoCivil/show?id=' + id);
    }

    indexEstadoCivil() {
        return axios.defaults.baseURL + 'api/EstadoCivil/index';
    }

    listEstadoCivil() {
        return axios.get('api/EstadoCivil/list');
    }

    storeEstadoCivil(item) {
        return axios.post('api/EstadoCivil/store', item);
    }

    destroyEstadoCivil(item) {
        return axios.post('api/EstadoCivil/destroy', item);
    }

    //Arma

    showArma(id) {
        return axios.get('api/Arma/show?id=' + id);
    }

    indexArma() {
        return axios.defaults.baseURL + 'api/Arma/index';
    }

    listArma() {
        return axios.get('api/Arma/list');
    }

    storeArma(item) {
        return axios.post('api/Arma/store', item);
    }

    destroyArma(item) {
        return axios.post('api/Arma/destroy', item);
    }
    //UnidadMedida

    showUnidadMedida(id) {
        return axios.get('api/UnidadMedida/show?id=' + id);
    }

    indexUnidadMedida() {
        return axios.defaults.baseURL + 'api/UnidadMedida/index';
    }

    listUnidadMedida() {
        return axios.get('api/UnidadMedida/list');
    }

    storeUnidadMedida(item) {
        return axios.post('api/UnidadMedida/store', item);
    }

    destroyUnidadMedida(item) {
        return axios.post('api/UnidadMedida/destroy', item);
    }
    //Cliente

    showCliente(id) {
        return axios.get('api/Cliente/show?id=' + id);
    }

    indexCliente() {
        return axios.defaults.baseURL + 'api/Cliente/index';
    }

    listCliente() {
        return axios.get('api/Cliente/list');
    }

    storeCliente(item) {
        return axios.post('api/Cliente/store', item);
    }

    destroyCliente(item) {
        return axios.post('api/Cliente/destroy', item);
    }

    select2Cliente(q) {
        return axios.get('api/Cliente/select2?q=' + q);
    }
    //Categoria

    showCategoria(id) {
        return axios.get('api/Categoria/show?id=' + id);
    }

    indexCategoria() {
        return axios.defaults.baseURL + 'api/Categoria/index';
    }

    listCategoria() {
        return axios.get('api/Categoria/list');
    }

    storeCategoria(item) {
        return axios.post('api/Categoria/store', item);
    }

    destroyCategoria(item) {
        return axios.post('api/Categoria/destroy', item);
    }
    //TipoProduccion

    showTipoProduccion(id) {
        return axios.get('api/TipoProduccion/show?id=' + id);
    }

    indexTipoProduccion() {
        return axios.defaults.baseURL + 'api/TipoProduccion/index';
    }

    listTipoProduccion() {
        return axios.get('api/TipoProduccion/list');
    }

    storeTipoProduccion(item) {
        return axios.post('api/TipoProduccion/store', item);
    }

    destroyTipoProduccion(item) {
        return axios.post('api/TipoProduccion/destroy', item);
    }
    //EstadoProduccion

    showEstadoProduccion(id) {
        return axios.get('api/EstadoProduccion/show?id=' + id);
    }

    indexEstadoProduccion() {
        return axios.defaults.baseURL + 'api/EstadoProduccion/index';
    }

    listEstadoProduccion() {
        return axios.get('api/EstadoProduccion/list');
    }

    storeEstadoProduccion(item) {
        return axios.post('api/EstadoProduccion/store', item);
    }

    destroyEstadoProduccion(item) {
        return axios.post('api/EstadoProduccion/destroy', item);
    }
    //RazonPerdida

    showRazonPerdida(id) {
        return axios.get('api/RazonPerdida/show?id=' + id);
    }

    indexRazonPerdida() {
        return axios.defaults.baseURL + 'api/RazonPerdida/index';
    }

    listRazonPerdida() {
        return axios.get('api/RazonPerdida/list');
    }

    storeRazonPerdida(item) {
        return axios.post('api/RazonPerdida/store', item);
    }

    destroyRazonPerdida(item) {
        return axios.post('api/RazonPerdida/destroy', item);
    }
    //TipoInspeccion

    showTipoInspeccion(id) {
        return axios.get('api/TipoInspeccion/show?id=' + id);
    }

    indexTipoInspeccion() {
        return axios.defaults.baseURL + 'api/TipoInspeccion/index';
    }

    listTipoInspeccion() {
        return axios.get('api/TipoInspeccion/list');
    }

    storeTipoInspeccion(item) {
        return axios.post('api/TipoInspeccion/store', item);
    }

    destroyTipoInspeccion(item) {
        return axios.post('api/TipoInspeccion/destroy', item);
    }
    //EstadoEntrega

    showEstadoEntrega(id) {
        return axios.get('api/EstadoEntrega/show?id=' + id);
    }

    indexEstadoEntrega() {
        return axios.defaults.baseURL + 'api/EstadoEntrega/index';
    }

    listEstadoEntrega() {
        return axios.get('api/EstadoEntrega/list');
    }

    storeEstadoEntrega(item) {
        return axios.post('api/EstadoEntrega/store', item);
    }

    destroyEstadoEntrega(item) {
        return axios.post('api/EstadoEntrega/destroy', item);
    }
    //TipoTamano

    showTipoTamano(id) {
        return axios.get('api/TipoTamano/show?id=' + id);
    }

    indexTipoTamano() {
        return axios.defaults.baseURL + 'api/TipoTamano/index';
    }

    listTipoTamano() {
        return axios.get('api/TipoTamano/list');
    }

    listTipoTamanoAlma() {
        return axios.get('api/TipoTamano/listAlma');
    }
    getTotalPorTipoTamano() {
        return axios.get('api/TipoTamano/getTotalPorTipoTamano');
    }

    listTipoTamanoVenta(id) {
        return axios.get('api/TipoTamano/listTipoTamano?id='+id);
    }

    listTamanos(productId) {
        return axios.get('api/TipoTamano/listTamanos?id='+productId);
    }

    storeTipoTamano(item) {
        return axios.post('api/TipoTamano/store', item);
    }

    destroyTipoTamano(item) {
        return axios.post('api/TipoTamano/destroy', item);
    }
    //CategoriaGasto

    showCategoriaGasto(id) {
        return axios.get('api/CategoriaGasto/show?id=' + id);
    }

    indexCategoriaGasto() {
        return axios.defaults.baseURL + 'api/CategoriaGasto/index';
    }

    listCategoriaGasto() {
        return axios.get('api/CategoriaGasto/list');
    }

    storeCategoriaGasto(item) {
        return axios.post('api/CategoriaGasto/store', item);
    }

    destroyCategoriaGasto(item) {
        return axios.post('api/CategoriaGasto/destroy', item);
    }
    //Parametro

    showParametro(id) {
        return axios.get('api/Parametro/show?id=' + id);
    }

    indexParametro() {
        return axios.defaults.baseURL + 'api/Parametro/index';
    }

    listParametro() {
        return axios.get('api/Parametro/list');
    }

    storeParametro(item) {
        return axios.post('api/Parametro/store', item);
    }

    destroyParametro(item) {
        return axios.post('api/Parametro/destroy', item);
    }
    //Producto

    showProducto(id) {
        return axios.get('api/Producto/show?id=' + id);
    }

    indexProducto() {
        return axios.defaults.baseURL + 'api/Producto/index';
    }

    listProducto() {
        return axios.get('api/Producto/list');
    }

    storeProducto(item) {
        return axios.post('api/Producto/store', item);
    }

    destroyProducto(item) {
        return axios.post('api/Producto/destroy', item);
    }
    //Produccion

    showProduccion(id) {
        return axios.get('api/Produccion/show?id=' + id);
    }

    indexProduccion() {
        return axios.defaults.baseURL + 'api/Produccion/index';
    }

    listProduccion() {
        return axios.get('api/Produccion/list');
    }

    listProduccionAlmacen() {
        return axios.get('api/Produccion/listAlmacen');
    }

    storeProduccion(item) {
        return axios.post('api/Produccion/store', item);
    }

    destroyProduccion(item) {
        return axios.post('api/Produccion/destroy', item);
    }
    //Almacenamiento

    showAlmacenamiento(id) {
        return axios.get('api/Almacenamiento/show?id=' + id);
    }
    getSumaPorTamano(id) {
        return axios.get('api/Almacenamiento/getSumaPorTamano?id=' + id);
    }

    indexAlmacenamiento() {
        return axios.defaults.baseURL + 'api/Almacenamiento/index';
    }

    listAlmacenamiento() {
        return axios.get('api/Almacenamiento/list');
    }

    storeAlmacenamiento(item) {
        return axios.post('api/Almacenamiento/store', item);
    }

    destroyAlmacenamiento(item) {
        return axios.post('api/Almacenamiento/destroy', item);
    }

  
    //Precio

    showPrecio(id) {
        return axios.get('api/Precio/show?id=' + id);
    }

    indexPrecio() {
        return axios.defaults.baseURL + 'api/Precio/index';
    }

    listPrecio() {
        return axios.get('api/Precio/list');
    }

    listPrecioVenta(id) {
        return axios.get('api/Precio/listPrecio?id='+id);
    }

    storePrecio(item) {
        return axios.post('api/Precio/store', item);
    }

    destroyPrecio(item) {
        return axios.post('api/Precio/destroy', item);
    }
    //Venta

    showVenta(id) {
        return axios.get('api/Venta/show?id=' + id);
    }

    indexVenta() {
        return axios.defaults.baseURL + 'api/Venta/index';
    }

    listVenta() {
        return axios.get('api/Venta/list');
    }

    storeVenta(item) {
        return axios.post('api/Venta/store', item);
    }

    destroyVenta(item) {
        return axios.post('api/Venta/destroy', item);
    }
    //Inspeccion

    showInspeccion(id) {
        return axios.get('api/Inspeccion/show?id=' + id);
    }

    indexInspeccion() {
        return axios.defaults.baseURL + 'api/Inspeccion/index';
    }

    listInspeccion() {
        return axios.get('api/Inspeccion/list');
    }

    storeInspeccion(item) {
        return axios.post('api/Inspeccion/store', item);
    }

    destroyInspeccion(item) {
        return axios.post('api/Inspeccion/destroy', item);
    }
    //Proveedor

    showProveedor(id) {
        return axios.get('api/Proveedor/show?id=' + id);
    }

    indexProveedor() {
        return axios.defaults.baseURL + 'api/Proveedor/index';
    }

    listProveedor() {
        return axios.get('api/Proveedor/list');
    }

    storeProveedor(item) {
        return axios.post('api/Proveedor/store', item);
    }

    destroyProveedor(item) {
        return axios.post('api/Proveedor/destroy', item);
    }
    //Entrega

    showEntrega(id) {
        return axios.get('api/Entrega/show?id=' + id);
    }

    indexEntrega() {
        return axios.defaults.baseURL + 'api/Entrega/index';
    }

    listEntrega() {
        return axios.get('api/Entrega/list');
    }

    storeEntrega(item) {
        return axios.post('api/Entrega/store', item);
    }

    destroyEntrega(item) {
        return axios.post('api/Entrega/destroy', item);
    }
    //Perdida

    showPerdida(id) {
        return axios.get('api/Perdida/show?id=' + id);
    }

    indexPerdida() {
        return axios.defaults.baseURL + 'api/Perdida/index';
    }

    listPerdida() {
        return axios.get('api/Perdida/list');
    }

    storePerdida(item) {
        return axios.post('api/Perdida/store', item);
    }

    destroyPerdida(item) {
        return axios.post('api/Perdida/destroy', item);
    }
    //Gasto

    showGasto(id) {
        return axios.get('api/Gasto/show?id=' + id);
    }

    indexGasto() {
        return axios.defaults.baseURL + 'api/Gasto/index';
    }

    listGasto() {
        return axios.get('api/Gasto/list');
    }

    storeGasto(item) {
        return axios.post('api/Gasto/store', item);
    }

    destroyGasto(item) {
        return axios.post('api/Gasto/destroy', item);
    }
    //Recibo

    showRecibo(id) {
        return axios.get('api/Recibo/show?id=' + id);
    }

    indexRecibo() {
        return axios.defaults.baseURL + 'api/Recibo/index';
    }

    listRecibo() {
        return axios.get('api/Recibo/list');
    }

    storeRecibo(item) {
        return axios.post('api/Recibo/store', item);
    }

    destroyRecibo(item) {
        return axios.post('api/Recibo/destroy', item);
    }


}