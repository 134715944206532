import { render, staticRenderFns } from "./ParametroPage.vue?vue&type=template&id=7b43ef48"
import script from "./ParametroPage.js?vue&type=script&lang=js&external"
export * from "./ParametroPage.js?vue&type=script&lang=js&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports