import { render, staticRenderFns } from "./PersonaPage.vue?vue&type=template&id=1b240c02"
import script from "./PersonaPage.js?vue&type=script&lang=js&external"
export * from "./PersonaPage.js?vue&type=script&lang=js&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports