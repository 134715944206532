import { render, staticRenderFns } from "./TipoProduccionPage.vue?vue&type=template&id=c9d3640e"
import script from "./TipoProduccionPage.js?vue&type=script&lang=js&external"
export * from "./TipoProduccionPage.js?vue&type=script&lang=js&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports