<script>
import Persona from '../pages/Persona/PersonaPage.vue'
import Departamento from '../pages/Departamento/DepartamentoPage.vue'
import Fuerza from '../pages/Fuerza/FuerzaPage.vue'
import Especialidad from '../pages/Especialidad/EspecialidadPage.vue'
import Cargo from '../pages/Cargo/CargoPage.vue' 
import Escalafon from '../pages/Escalafon/EscalafonPage.vue'
import Grado from '../pages/Grado/GradoPage.vue'
import Reparticion from '../pages/Reparticion/ReparticionPage.vue'
import Profile from '../pages/Profile/ProfilePage.vue'
import Login from '../pages/Login/LoginPage.vue'
import Sexo from '../pages/Sexo/SexoPage.vue'
import GrupoSanguineo from '../pages/GrupoSanguineo/GrupoSanguineoPage.vue'
import EstadoCivil from '../pages/EstadoCivil/EstadoCivilPage.vue'
import Arma from '../pages/Arma/ArmaPage.vue'
import Rol from '../pages/Rol/RolPage.vue'
import UnidadMedida from '../pages/UnidadMedida/UnidadMedidaPage.vue'
import Cliente from '../pages/Cliente/ClientePage.vue'
import Categoria from '../pages/Categoria/CategoriaPage.vue'
import TipoProduccion from '../pages/TipoProduccion/TipoProduccionPage.vue'
import EstadoProduccion from '../pages/EstadoProduccion/EstadoProduccionPage.vue'
import TipoTamano from '../pages/TipoTamano/TipoTamanoPage.vue'
import RazonPerdida from '../pages/RazonPerdida/RazonPerdidaPage.vue'
import TipoInspeccion from '../pages/TipoInspeccion/TipoInspeccionPage.vue'
import EstadoEntrega from '../pages/EstadoEntrega/EstadoEntregaPage.vue'
import CategoriaGasto from '../pages/CategoriaGasto/CategoriaGastoPage.vue'
import Parametro from '../pages/Parametro/ParametroPage.vue'
import Producto from '../pages/Producto/ProductoPage.vue'
import Produccion from '../pages/Produccion/ProduccionPage.vue'
import Almacenamiento from '../pages/Almacenamiento/AlmacenamientoPage.vue'
import Precio from '../pages/Precio/PrecioPage.vue'
import Venta from '../pages/Venta/VentaPage.vue'
import Inspeccion from '../pages/Inspeccion/InspeccionPage.vue'
import Proveedor from '../pages/Proveedor/ProveedorPage.vue'
import Entrega from '../pages/Entrega/EntregaPage.vue'
import Perdida from '../pages/Perdida/PerdidaPage.vue'
import Gasto from '../pages/Gasto/GastoPage.vue'
import Recibo from '../pages/Recibo/ReciboPage.vue'
import DashboardV2 from '../pages/Dashboard-v2.vue'

const routes = [
  { path: '/Persona', component: Persona },
  { path: '/Departamento', component: Departamento },
  { path: '/Fuerza', component: Fuerza },
  { path: '/Especialidad', component: Especialidad },
  { path: '/Cargo', component: Cargo },
  { path: '/Escalafon', component: Escalafon },
  { path: '/Grado', component: Grado },
  { path: '/Reparticion', component: Reparticion },
  { path: '/Profile', component: Profile },
  { path: '/', component: Login },
  { path: '/Sexo', component: Sexo },
  { path: '/GrupoSanguineo', component: GrupoSanguineo },
  { path: '/EstadoCivil', component: EstadoCivil },
  { path: '/Arma', component: Arma },
  { path: '/Rol', component: Rol },
  { path: '/UnidadMedida', component: UnidadMedida },
  { path: '/Cliente', component: Cliente },
  { path: '/Categoria', component: Categoria },
  { path: '/TipoProduccion', component: TipoProduccion },
  { path: '/EstadoProduccion', component: EstadoProduccion },
  { path: '/RazonPerdida', component: RazonPerdida },
  { path: '/TipoInspeccion', component: TipoInspeccion },
  { path: '/EstadoEntrega', component: EstadoEntrega },
  { path: '/TipoTamano', component: TipoTamano },
  { path: '/CategoriaGasto', component: CategoriaGasto },
  { path: '/Parametro', component: Parametro },
  { path: '/Producto', component: Producto },
  { path: '/Produccion', component: Produccion },
  { path: '/Almacenamiento', component: Almacenamiento },
  { path: '/Precio', component: Precio },
  { path: '/Venta', component: Venta },
  { path: '/Inspeccion', component: Inspeccion },
  { path: '/Proveedor', component: Proveedor },
  { path: '/Entrega', component: Entrega },
  { path: '/Perdida', component: Perdida },
  { path: '/Gasto', component: Gasto },
  { path: '/Recibo', component: Recibo },
  { path: '/dashboard/v2', component: DashboardV2 },
]

export default routes;
</script>
