import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "VentaPage",
    data() {
        let ss = new MainService();
        return {
            msg: "VentaPage",
            ss: ss,
            auth: {},

            ajax: {
                "url": ss.indexVenta(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'Fecha', name: 'Fecha', title: 'Fecha' },
                { data: 'Cliente', name: 'Cliente', title: 'Cliente' },
                { data: 'Producto', name: 'Producto', title: 'Producto' },
                { data: 'TipoTamano', name: 'TipoTamano', title: 'Tipo de Tamaño' },
                { data: 'CantidadVendida', name: 'CantidadVendida', title: 'Cantidad Vendida' },
                { data: 'Precio', name: 'Precio', title: 'Precio Vendido' },
                { data: 'Total', name: 'Total', title: 'Total' },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                }
            ],
            isLoading: false,
            isLoadingFile: false,
            errorBag: {},
            ventas: [],
            venta: {
                Producto: null,
                TipoTamano: null,
                Precio: null,
                CantidadVendida: null,
                PrecioVendido: 0,
                Total: 0,
                MaplesVendidos: null,
                PrecioMayorista: null,
            },
            allowTotalEdit: false,
            originalTotal: 0,
            originalPrecio: 0,
            mostrarVentaUnidad: true,
            mostrarVentaMaple: false,
            clientes: [],
            totalPorTipoTamano: [],
            tipotamanos: [],
            productos: [],
            precios: [],
            placeholderCliente: "",
            selectedCliente: null,
            searchCliente: '',
            isMaple: false,
            isUnidad: false,
        };
    },
    methods: {
        newVenta() {
            this.venta = {
                Producto: null,
                TipoTamano: null,
                Precio: null,
                CantidadVendida: 0,
                PrecioVendido: 0,
                Total: 0
            };
            this.$refs['frm-venta'].show();
        },
        showVenta(id) {
            this.isLoading = true;
            this.ss.showVenta(id).then(
                (result) => {
                    let response = result.data;
                    this.venta = response.data;
                    this.$refs['view-venta'].show();
                    this.isLoading = false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading = false;
            });
        },
        editVenta() {
            this.$refs['frm-venta'].show();
            this.$refs['view-venta'].hide();
        },
        cancelVenta() {
            if (this.venta.id) {
                this.$refs['view-venta'].show();
            }
            this.$refs['frm-venta'].hide();
        },
        saveVenta() {
            this.ss.storeVenta(this.venta).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-venta'].hide();
                    this.$refs['datatable-venta'].reload();
                    this.fetchTotalesPorTipoTamano();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    console.log(error.response.data);
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        deleteVenta() {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
                showCancelButton: true,
                cancelButtonColor: '#d33',
            })
                .then((willDelete) => {
                    if (willDelete.isConfirmed) {
                        this.ss.destroyVenta(this.venta)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-venta'].hide();
                                this.$refs['datatable-venta'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        draw() {
            window.$('.btn-datatable-Venta').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showVenta(data);
            });
        },
        fetchTotalesPorTipoTamano() {
            this.ss.getTotalPorTipoTamano().then(
                (result) => {
                    let response = result.data;
                    this.totalPorTipoTamano = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        // getCliente() {
        //     this.ss.listCliente().then(
        //         (result) => {
        //             let response = result.data;
        //             this.clientes = response.data;
        //         }
        //     ).catch(error => {
        //         console.log(error);
        //     });
        // },
        getProducto() {
            this.ss.listProducto().then(
                (result) => {
                    let response = result.data;
                    this.productos = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        fetchTipoTamanos() {
            this.ss.listTipoTamanoVenta(this.venta.Producto).then((result) => {
                let response = result.data;
                this.tipotamanos = response.data;
                this.venta.TipoTamano = null; // Limpiar el tipo de tamaño seleccionado
                this.venta.Precio = null; // Limpiar el precio seleccionado
                this.venta.PrecioVendido = 0; // Reiniciar el precio vendido
                this.venta.Total = 0; // Reiniciar el total
            }).catch((error) => {
                console.log(error);
            });
        },
        //  calcularTotal() {
        //     const cantidadVendida = parseFloat(this.venta.CantidadVendida) || 0;
        //     const precioVendido = parseFloat(this.venta.PrecioVendido) || 0;

        //     // Si no está habilitada la edición manual, calcular automáticamente el total
        //     if (!this.allowTotalEdit) {
        //         this.venta.Total = (cantidadVendida * precioVendido).toFixed(2);
        //     }
        // }, 
        // Mantén los otros métodos como están...
        fetchPrecios() {
            this.ss.listPrecioVenta(this.venta.TipoTamano).then((result) => {
                let response = result.data;
                if (response.data.length > 0) {
                    let precio = response.data[0];
                    this.venta.Precio = precio.id;  // Guardar el ID del precio
                    this.venta.PrecioVendido = precio.PrecioUnitario;  // Asignar el valor del precio unitario
                    this.venta.PrecioMayorista = precio.PrecioMayorista;
                    this.calcularTotal();  // Llamar al método de cálculo del total
                } else {
                    this.venta.Precio = null;
                    this.venta.PrecioVendido = 0;
                    this.venta.PrecioMayorista = 0;
                    this.calcularTotal();  // Actualizar el total
                }
            }).catch((error) => {
                console.log(error);
            });
        },
        // Calcular el total para la venta por unidades
        calcularTotalUnidad() {
            const cantidadVendida = parseFloat(this.venta.CantidadVendida) || 0;
            const precioVendido = parseFloat(this.venta.PrecioVendido) || 0;

            // Solo actualiza el total si estás vendiendo por unidad
            if (this.mostrarVentaUnidad) {
                this.venta.Total = (cantidadVendida * precioVendido).toFixed(2);
            }
        },

        calcularTotalMaple() {
            const maplesVendidos = parseFloat(this.venta.MaplesVendidos) || 0;
            const precioMayorista = parseFloat(this.venta.PrecioMayorista) || 0;

            // Convertir maples a unidades (1 maple = 30 unidades)
            this.venta.CantidadVendida = maplesVendidos * 30;

            const totalMaple = (maplesVendidos * precioMayorista).toFixed(2);
            console.log(totalMaple, 'total maple chat');
            // Calcular el total en la misma variable Total
            if (this.mostrarVentaMaple) {
                this.venta.Total = totalMaple;
            }
        },
        toggleVentaPorUnidad() {
            this.mostrarVentaUnidad = true;
            this.mostrarVentaMaple = false;
            this.resetearValores();
        },
        toggleVentaPorMaple() {
            this.mostrarVentaUnidad = false;
            this.mostrarVentaMaple = true;
            this.resetearValores();
        },
        resetearValores() {
            this.venta.CantidadVendida = null;
            this.venta.MaplesVendidos = null;
            this.venta.Total = null;
        },
    },
    watch: {
        'venta.CantidadVendida': function (newVal) {
            if (this.venta && typeof newVal === 'number') {
                this.calcularTotalUnidad();
            }
        },
        searchCliente(q) {
            this.ss.select2Cliente(q).then((res) => {
                if (res.data.success) {

                    this.clientes = res.data.items;

                } else {
                    this.clientes = [];
                }
            })
        },
        allowTotalEdit(newValue) {
            if (newValue) {
                // Al permitir editar, almacenamos los valores originales
                this.originalTotal = this.venta.Total;
                this.originalPrecio = this.venta.PrecioVendido;
            } else {
                // Si desmarcamos el checkbox, restauramos los valores originales
                this.venta.Total = this.originalTotal;
                this.venta.PrecioVendido = this.originalPrecio;
            }
        }
    },

    components: {
        dataTable,
        Loader
    },
    mounted() {

        var persona = JSON.parse(localStorage.getItem('persona'));
        this.auth = JSON.parse(localStorage.getItem("persona"));
        if (!persona) {
            this.$router.push('/Login');
        } else {
            //this.getCliente();
            this.fetchTotalesPorTipoTamano();
            this.getProducto();
        }
    }
};
