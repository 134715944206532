import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "PrecioPage",
    data() {
        let ss = new MainService();
        return {
            msg: "PrecioPage",
            ss: ss,
            auth: {},

            ajax: {
                "url": ss.indexPrecio(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'nombreProducto', name: 'pr.Producto', title: 'Producto' },
                { data: 'TipoTamano', name: 'tt.Nombre', title: 'Tipo Tamaño' },
                { data: 'PrecioUnitario', name: 'pe.PrecioUnitario', title: 'Precio Unitario' },
                { data: 'PrecioMayorista', name: 'pe.PrecioMayorista', title: 'Precio Mayorista' },
                { data: 'Fecha', name: 'pe.Fecha', title: 'Fecha' },
                { data: 'Activo', name: 'pe.Activo', title: 'Activo', searchable: false, width: '10%',
                    className: 'centradito',
                    render: function (data, type, row) {
                        if (row.Activo == false) {
                            return '<i class="fa fa-ban text-danger"> No Activo</i>';
                        } else {
                            return '<i class="fa fa-check text-success"> Activo</i>';
                        }
                    },
                },
                { data: 'FechaFinalizacion', name: 'pe.FechaFinalizacion', title: 'Fecha Finalización' },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],

            isLoading: false,
            isLoadingFile: false,
            errorBag: {},
            precios: [],
            precio: {
                Producto: null,
                TipoTamano: null,
                PrecioUnitario: 0,
                PrecioMayorista: 0,
                Fecha: null
            },
            productos: [],
            tamanos: [],
        };
    },
    methods: {
        newPrecio() {
            this.precio = {};
            this.$refs['frm-precio'].show();
        },
        showPrecio(id) {
            this.isLoading = true;
            this.ss.showPrecio(id).then(
                (result) => {
                    let response = result.data;
                    this.precio = response.data;
                    this.$refs['view-precio'].show();
                    this.isLoading = false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading = false;
            });
        },
        editPrecio() {
            this.$refs['frm-precio'].show();
            this.$refs['view-precio'].hide();
        },
        cancelPrecio() {
            if (this.precio.id) {
                this.$refs['view-precio'].show();
            }
            this.$refs['frm-precio'].hide();
        },
        savePrecio() {
            this.ss.storePrecio(this.precio).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-precio'].hide();
                    this.$refs['datatable-precio'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    console.log(error.response.data);
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        deletePrecio() {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
                showCancelButton: true,
                cancelButtonColor: '#d33',
            })
                .then((willDelete) => {
                    if (willDelete.isConfirmed) {
                        this.ss.destroyPrecio(this.precio)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-precio'].hide();
                                this.$refs['datatable-precio'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        draw() {
            window.$('.btn-datatable-Precio').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showPrecio(data);
            });
        },
        getProducto() {
            this.ss.listProducto().then(
                (result) => {
                    let response = result.data;
                    this.productos = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        fetchTamanos() {
            if (this.precio.Producto) {
                this.ss.listTamanos(this.precio.Producto).then(response => {
                    this.tamanos = response.data;
                }).catch(error => {
                    console.log(error);
                });
            }
        },
        calcularPrecioUnitario() {
            if (this.precio.PrecioMayorista) {
                // Calcular precio unitario dividiendo por 30
                this.precio.PrecioUnitario = (this.precio.PrecioMayorista / 30).toFixed(2);
            } else {
                this.precio.PrecioUnitario = 0;
            }
        },
    },
    components: {
        dataTable,
        Loader
    },
    mounted() {

        var persona = JSON.parse(localStorage.getItem('persona'));
        this.auth = JSON.parse(localStorage.getItem("persona"));
        if (!persona) {
            this.$router.push('/Login');
        } else {
            this.getProducto();
        }
    }
};
